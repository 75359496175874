import { Box, Typography } from '@mui/material';
import {
  createErrorNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import { modalControllerAtom } from 'atoms/atomModalController';
import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import Input from 'common/Input';
import { MODAL_TYPES, ModalSize } from 'common/interfaces/enums';
import ModalComposed from 'common/modal/ModalComposed';
import { useRef, useState, type ReactElement } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { GAUserEvent, REGEX_HTTP } from 'utils/utils';
import './UploadLink.scss';

const UploadLink = (): ReactElement => {
  const exposeService = ExposeService.getInstance();
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = useState<boolean>();
  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);

  const showErrorAlert = (message?: string): void => {
    createErrorNotification(
      message !== undefined
        ? `${message}`
        : 'Something went wrong when uploading the selected file',
      setNotifyMessage
    );
  };

  const handleContinue = (): void => {
    const link = inputRef.current?.value;
    if (link !== undefined) {
      exposeService
        .uploadAsset(link, false)
        .then((uploadedDataReference) => {
          const { runId, fileId } = uploadedDataReference;
          if (runId !== undefined && fileId !== undefined) {
            GAUserEvent(
              `${userLocationVariable.current}_${
                USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET as string
              }`
            );
            setUserLocationVariable({
              ...userLocationVariable,
              current: USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET,
              previous: userLocationVariable.current
            });
            setModalController({
              type: MODAL_TYPES.UPLOAD_MANAGER,
              payload: {
                fileId,
                runId
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          showErrorAlert(error);
        });
    }
  };

  const onEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isValid !== undefined && isValid) {
        handleContinue();
      }
    }
  };

  const inputValidation = (newText: string): void => {
    const regexTest = new RegExp(REGEX_HTTP);
    if (regexTest.test(newText)) {
      setIsValid(true);
    } else if (isValid === true) {
      setIsValid(false);
    }
  };

  const closeModal = (): void => {
    const isDataset = userLocationVariable.current.includes(
      USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET
    );
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    setModalController({
      type: isDataset ? MODAL_TYPES.LOAD_DATASETS : MODAL_TYPES.LOAD_MODELS,
      payload: modalController.payload
    });
  };

  const bodyComponent = (
    <Box id="upload-link">
      <Box id="upload-link-modal-header">
        <Typography className="sm-modal-title-header">
          Link to your data
        </Typography>
        <Typography className="sm-modal-body-title">
          If you have stored a dataset in an online public location, please
          simply provide the direct link here to import the dataset.
        </Typography>
      </Box>
      <Box id="upload-link-modal-body">
        <Input
          ref={inputRef}
          onChange={(text: string) => {
            text !== undefined && inputValidation(text.replace(/['"]+/g, ''));
          }}
          enterAction={onEnter}
        />
      </Box>
      <Box className="sm-modal-footer">
        <CustomButton variant="secondary" onClick={closeModal}>
          Cancel
        </CustomButton>
        <CustomButton
          disabled={isValid !== true}
          style={{ borderColor: '#D0D5DD' }}
          onClick={handleContinue}
        >
          Link dataset
        </CustomButton>
      </Box>
    </Box>
  );

  return (
    <ModalComposed
      id="upload-link-wrapper"
      className="sm-modal-wrapper"
      open={true}
      size={ModalSize.SMALL}
      body={bodyComponent}
    />
  );
};

export default UploadLink;
