import { modalControllerAtom } from 'atoms/atomModalController';
import BulkPredictions from 'bulkPredictions/bulkPredictions/BulkPredictions';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import { MODAL_TYPES } from 'common/interfaces/enums';
import Transformation from 'featureEngineering/Transformation';
import Insights from 'insights/Insights';
import MergeDatasets from 'mergeDatasets/MergeDatasets';
import MergeDatasetsPreview from 'mergeDatasets/mergePreview/MergeDatasetsPreview';
import CrossTabs from 'mySpace/crossTabs/CrossTabs';
import Details from 'mySpace/details/Details';
import Load from 'mySpace/load/Load';
import Save from 'mySpace/save/Save';
import DataLayer from 'playground/data/dataLayer';
import type { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import Login from 'home/components/Login';
import ForgotPassword from 'home/components/ForgotPassword';
import LinkSent from 'home/components/LinkSent';
import ResetPassword from 'home/components/resetPassword/ResetPassword';
import StartChat from 'home/components/startChat/StartChat';
import './ModalController.scss';
import type { Dataset, Model } from 'common/interfaces/interfaces';
import UploadLink from 'mySpace/uploadManager/uploadLink/UploadLink';
import UploadManager from 'mySpace/uploadManager/UploadManager';

const ModalController = (): ReactElement => {
  const assistantApiService = AssistantApiService.getInstance();
  const modalController = useRecoilValue(modalControllerAtom);
  const deleteCountDown = (): void => {
    //  TODO: need some context on what this is doing
    const disableAssetButton: boolean =
      modalController?.payload?.disable_asset_button ?? false;
    if (!disableAssetButton) {
      assistantApiService.deleteCountDown().catch(console.error);
    }
  };
  switch (modalController.type.toUpperCase()) {
    case MODAL_TYPES.START_CHAT:
      return <StartChat />;
    case MODAL_TYPES.LOGIN:
      return <Login />;
    case MODAL_TYPES.FORGOT_PASSWORD:
      return <ForgotPassword />;
    case MODAL_TYPES.LINK_SENT:
      return <LinkSent />;
    case MODAL_TYPES.RESET_PASSWORD:
      return <ResetPassword />;
    case MODAL_TYPES.SAVE_MODEL:
    case MODAL_TYPES.SAVE_DATASET:
      deleteCountDown();
      return <Save />;
    case MODAL_TYPES.INSIGHT_MODEL:
    case MODAL_TYPES.INSIGHT_DATASETS: {
      deleteCountDown();
      const {
        isInference,
        isView,
        openChat,
        asset
      }: {
        isView?: boolean;
        isInference?: boolean;
        openChat?: boolean;
        asset: Dataset | Model;
      } = modalController.payload;
      return (
        <Details
          selectedAsset={asset}
          isInference={Boolean(isInference)}
          isView={Boolean(isView)}
          openChat={Boolean(openChat)}
        />
      );
    }
    case MODAL_TYPES.UPLOAD_LINK:
      return <UploadLink />;
    case MODAL_TYPES.UPLOAD_MANAGER: {
      const { fileId, runId, uri } = modalController.payload;
      return <UploadManager fileId={fileId} uri={uri} runId={runId} />;
    }
    case MODAL_TYPES.CROSS_TABS:
      return (
        <CrossTabs
          datasetId={modalController.payload.datasetId}
          mainGroup={modalController.payload.mainGroup}
          viewId={modalController.payload?.viewId}
        />
      );
    case MODAL_TYPES.LOAD_MODELS:
    case MODAL_TYPES.LOAD_DATASETS:
      deleteCountDown();
      return <Load />;
    case MODAL_TYPES.PLAYGROUND:
      deleteCountDown();
      return (
        <DataLayer
          selectedRowData={modalController.payload?.selectedRowData}
          returnValues={modalController.payload?.returnValues}
          filters={modalController.payload?.filters}
        />
      );
    case MODAL_TYPES.MERGE_DATASETS:
      return <MergeDatasets />;
    case MODAL_TYPES.MERGE_DATASETS_PREVIEW:
      deleteCountDown();
      return (
        <MergeDatasetsPreview
          tableData={modalController.payload.tableData}
          keyIdentifiers={modalController.payload.keyIdentifiers}
        />
      );
    case MODAL_TYPES.TRANSFORMATION:
      return <Transformation />;
    case MODAL_TYPES.BULK_PREDICTIONS:
      deleteCountDown();
      return (
        <BulkPredictions
          asset={modalController.payload.asset}
          selectedRowData={modalController.payload.selectedRowData}
          playgroundReturnData={modalController.payload.playgroundReturnData}
          newFilters={modalController.payload.newFilters}
        />
      );
    case MODAL_TYPES.INSIGHTS:
      deleteCountDown();
      return (
        <Insights
          goal={modalController.payload.goal}
          statement={modalController.payload.statement}
          insights={modalController.payload.insight}
          plot={modalController.payload.plot}
          downloadURL={modalController.payload.download_url}
          datasetId={modalController.payload.dataset_id}
          originalDatasetId={modalController.payload.original_dataset_id}
          features={modalController.payload?.features}
        />
      );
  }
  return <></>;
};
export default ModalController;
