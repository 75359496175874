import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState, type ReactElement } from 'react';

import {
  ACCESS_TYPES,
  MODAL_TYPES,
  ModalSize,
  RESTRICTIONS
} from 'common/interfaces/enums';

import ModalComposed from 'common/modal/ModalComposed';

import { modalControllerAtom } from 'atoms/atomModalController';
import { useRecoilState } from 'recoil';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CustomButton from 'common/button/CustomButton';
import {
  USER_TRACKING_DETAILS_ACTIONS,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { ExposeService } from 'services/ExposeService';

import AssetsForm from 'mySpace/components/assetsInputsByIntent/AssetsForm';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import type {
  Dataset,
  Model,
  NotifyBoxTypes,
  SaveUploadedTypes
} from 'common/interfaces/interfaces';
import { GAUserEvent } from 'utils/utils';
import NotifyBox from 'common/notifyBox/NotifyBox';

import 'common/Common.scss';
import './UploadManager.scss';

//  TODO: Track the status of the uploaded file with the runId

const UploadManager = ({
  fileId,
  uri,
  runId
}: {
  fileId: string;
  uri: string;
  runId: string;
}): ReactElement => {
  const authenticationService = AuthenticationService.getInstance();
  const exposeService = ExposeService.getInstance();

  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const access = authenticationService.getAccess();
  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [counter, setCounter] = useState(0);
  const valueCounter = useRef(0);
  const [isSaving, setIsSaving] = useState(false);

  const [messageError, setMessageError] = useState<null | NotifyBoxTypes>(null);
  const [asset, setAsset] = useState<Model | Dataset | undefined>();
  const [name, setName] = useState<string>('');
  const nameRef = useRef<HTMLInputElement>(null);
  const [validName, setValidName] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<ACCESS_TYPES>(
    ACCESS_TYPES.PRIVATE
  );
  const [tags, setTags] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const approveSaveUpload = name !== '' && validName;

  const isDataset = userLocationVariable.current.includes(
    USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET
  );

  useEffect(() => {
    const statusInterval: NodeJS.Timer = setInterval(() => {
      valueCounter.current++;
      setCounter(valueCounter.current);
      (isDataset
        ? exposeService.getUserDatasets()
        : exposeService.getUserModels()
      )
        .then((response) => {
          if (response?.data !== undefined) {
            const found = response.data.find((asset) => {
              if (uri === undefined) {
                return fileId.includes(asset.name) && runId.includes(asset.id);
              }
              const start = uri.indexOf('temp/');
              const end = uri.indexOf('.csv');
              return uri
                .slice(start + 'temp/'.length, end)
                .includes(asset.name);
            });
            const foundSchema: Dataset['schema'] = (found as Dataset).schema;
            if (
              found !== undefined &&
              (found.context.length > 0 ||
                (foundSchema !== undefined && foundSchema.length > 0))
            ) {
              setDisableContinueButton(false);
              setAsset(found);
              clearInterval(statusInterval);
            }
          }
        })
        .catch((error) => {
          clearInterval(statusInterval);
          setMessageError({ type: 'failed', message: error.message });
        });

      if (counter > 12) {
        clearInterval(statusInterval);
        setMessageError({
          type: 'failed',
          message:
            'Something went wrong when uploading your data, plase try again later'
        });
      }
    }, 10000);
    return () => {
      clearInterval(statusInterval);
    };
  }, []);

  const handleSave = (): void => {
    const filteredTags = tags.filter(
      (tag) => tag !== ACCESS_TYPES.TEMPORARILY_STORED
    );

    const collectedSaveData: SaveUploadedTypes = {
      name,
      description,
      visibility,
      tags: filteredTags
    };

    if (isDataset && asset !== undefined) {
      exposeService
        .updateDataset(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataset ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
            previousModal({ uploadedId: asset.id });
          } else {
            setMessageError({
              type: 'error',
              message: 'Dataset data not found'
            });
          }
        })
        .catch((error) => {
          setMessageError({ type: 'error', message: error.message });
        });
    } else if (asset !== undefined) {
      exposeService
        .updateModel(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            GAUserEvent(
              `${userLocationVariable.current}_${
                (isDataset ? 'DATASET_' : 'MODEL_') +
                USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
            previousModal({ uploadedId: asset.id });
          } else {
            setMessageError({ type: 'error', message: 'Model data not found' });
          }
        })
        .catch((error) => {
          setMessageError({ type: 'error', message: error.message });
        });
    }
  };

  const onSaveEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isSaving && approveSaveUpload) handleSave();
    }
  };

  const handleContinue = (): void => {
    if (isSaving && approveSaveUpload) {
      handleSave();
    } else if (!isSaving) {
      setIsSaving(true);
    }
  };

  const closeModal = (): void => {
    if (!disableContinueButton) {
      setModalController({ type: MODAL_TYPES.CLOSE });
    }
  };

  const previousModal = (addPayload = {}): void => {
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    setModalController({
      type: isDataset ? MODAL_TYPES.LOAD_DATASETS : MODAL_TYPES.LOAD_MODELS,
      payload: { disable_asset_button: true, ...addPayload }
    });
  };

  const headerComponent = (
    <Box
      style={{
        display: 'flex'
      }}
    >
      <Typography className="sm-modal-title-header">
        Adding new dataset
      </Typography>
      <CloseIcon
        style={{
          marginLeft: 'auto',
          cursor: 'pointer',
          opacity: disableContinueButton ? 0.5 : 1
        }}
        onClick={closeModal}
      />
    </Box>
  );

  const bodyComponent = (
    <>
      <Box className="upload">
        <Box className="upload__process-breadcrumbs">
          <Box className={isSaving ? '' : 'active'}>1</Box>
          preparing data
          <span />
          <Box className={isSaving ? 'active' : ''}>2</Box>
          saving
        </Box>
        <Box className="upload__content">
          <Box className="upload__content__title">
            {isSaving ? 'Saving dataset' : 'Processing your dataset'}
            <Typography className="upload__content__description">
              {isSaving
                ? 'Datasets saved will be available from your personal space'
                : `Thank you for uploading your dataset. We are now proceeding with the
            process of loading and cleaning it before saving.`}
            </Typography>
          </Box>
          {isSaving ? (
            <AssetsForm
              modalController={modalController.type}
              inputControllers={{
                stateName: { name, setName, nameRef, validName, setValidName },
                stateDescription: {
                  description,
                  setDescription,
                  descriptionRef
                },
                stateAccess: { access, visibility, setVisibility },
                stateTags: [tags, setTags],
                shared: Boolean(sharedResources?.defaultValue)
              }}
              onEnter={onSaveEnter}
              disableInputs={false}
              messageError={messageError}
            />
          ) : (
            <Box className="upload__loading">
              <Box className="upload__loading__progress">
                {counter > 1 ? (
                  <CheckCircleOutlineIcon color="secondary" />
                ) : messageError !== null ? (
                  <CancelOutlinedIcon />
                ) : (
                  <LoadingDots />
                )}
                <Box>
                  <Typography>Link or Upload file</Typography>
                  <Box className="upload__loading__progress__details">
                    <Typography>Formatting file</Typography>|
                    <Typography>Removing empty columns</Typography>|
                    <Typography>Saving to cloud</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="upload__loading__progress">
                {counter > 2 ? (
                  <CheckCircleOutlineIcon color="secondary" />
                ) : messageError !== null ? (
                  <CancelOutlinedIcon />
                ) : (
                  <LoadingDots />
                )}
                <Box>
                  <Typography>Reading dataset</Typography>
                  <Box className="upload__loading__progress__details">
                    <Typography>Reviewing column values</Typography>|
                    <Typography>Statistically analysing data types</Typography>|
                    <Typography>Analysing missing data percentage</Typography>|
                    <Typography>Choosing imputation method</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="upload__loading__progress">
                {counter > 3 && asset !== undefined ? (
                  <CheckCircleOutlineIcon color="secondary" />
                ) : messageError !== null ? (
                  <CancelOutlinedIcon />
                ) : (
                  <LoadingDots />
                )}
                <Box>
                  <Typography>Cleaning Data</Typography>
                  <Box className="upload__loading__progress__details">
                    <Typography>
                      Extract features from date/time columns
                    </Typography>
                    |
                    <Typography>
                      Extract categories from date/time columns
                    </Typography>
                    |
                    <Typography>Format numerical columns to decimal</Typography>
                    |<Typography>Analysing and extract categories</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {messageError !== null ? (
          <Box
            sx={{
              width: '50%',
              margin: '1em 1em 2em 0em'
            }}
          >
            <NotifyBox
              type={messageError.type}
              message={messageError.message}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );

  const footerComponent = (
    <Box id="upload-footer">
      <CustomButton variant="secondary" onClick={previousModal}>
        cancel
      </CustomButton>
      <CustomButton
        variant="primary"
        disabled={disableContinueButton || (isSaving && !approveSaveUpload)}
        onClick={handleContinue}
      >
        {isSaving ? 'Save' : 'Continue'}
      </CustomButton>
    </Box>
  );

  return (
    <ModalComposed
      open={modalController.type === MODAL_TYPES.UPLOAD_MANAGER}
      id="upload-manager-wrapper"
      size={ModalSize.COMPOSED_FULL}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default UploadManager;
