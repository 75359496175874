import { useEffect, useState, type ReactElement } from 'react';

import {
  Alert,
  Box,
  Divider,
  Snackbar,
  Typography,
  type AlertColor
} from '@mui/material';

import type { Row, TableData } from 'common/interfaces/interfaces';

import { ICON_TYPE, MODAL_TYPES, ModalSize } from 'common/interfaces/enums';

import ModalComposed from 'common/modal/ModalComposed';

import CloseIcon from '@mui/icons-material/Close';
import { modalControllerAtom } from 'atoms/atomModalController';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  previewfiltersStateAtom,
  type FilterOptions
} from 'bulkPredictions/atoms/atomPreviewfilersState';
import SummaryLayer, {
  type Asset
} from 'bulkPredictions/components/summary-layer/SummaryLayer';
import _ from 'lodash';
import Papa from 'papaparse';
import { GAUserEvent, downloadDocumentFromUrl } from 'utils/utils';

import {
  TRACKED_USER_ACTION_PREDICT_FLOW,
  USER_TRACKING_LOCATION_NAMES,
  USER_TRACKING_SUMMARY_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './BulkPredictions.scss';

export interface PlaygroundReturns {
  selectedRowData?: Row;
  playgroundReturnData?: Asset;
  newFilters?: FilterOptions;
}

interface BulkPredictionsProps extends PlaygroundReturns {
  asset?: Asset;
}

const BulkPredictions = ({
  selectedRowData,
  playgroundReturnData,
  newFilters,
  asset
}: BulkPredictionsProps): ReactElement => {
  const [modalController] = useRecoilState(modalControllerAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const resetAtomModalController = useResetRecoilState(modalControllerAtom);
  const resetAtomFilterOptions = useResetRecoilState(previewfiltersStateAtom);
  const [tableData, setTableData] = useState<TableData>();

  const [snackBar, setSnackBar] = useState<{
    status: AlertColor;
    open: boolean;
    message: string;
  }>({ status: 'info', open: false, message: '' });

  const assetData = asset ?? playgroundReturnData;

  useEffect(() => {
    if (
      !userLocationVariable.current.includes(
        USER_TRACKING_LOCATION_NAMES.INSIGHTS
      )
    ) {
      setUserLocationVariable({
        ...userLocationVariable,
        current: `${userLocationVariable.current}_${USER_TRACKING_LOCATION_NAMES.INSIGHTS}`,
        previous: userLocationVariable.current
      });
    }
    if (
      assetData !== undefined &&
      'documentUrl' in assetData &&
      assetData?.documentUrl !== undefined &&
      assetData?.documentUrl !== null &&
      tableData === undefined
    ) {
      openCSVFile(assetData.documentUrl);
    }
    return () => {
      resetAtomFilterOptions();
    };
  }, [assetData]);

  const closeModal = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.CLOSE}`
    );
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    resetAtomModalController();
  };

  const generateSummaryLayer = (): ReactElement => {
    if (tableData === undefined) {
      return <></>;
    }
    return (
      <Box className="bulk-predictions-body-tables">
        <SummaryLayer
          asset={assetData}
          tableData={tableData}
          disableFirstSwitchButton={true}
          bulkPredictions={true}
          options={{ selectedRowData, playgroundReturnData, newFilters }}
        />
      </Box>
    );
  };

  const convertStringValuesNumericalColumnToNumber = (row: Row): Row => {
    if (
      assetData?.features?.numerical !== undefined &&
      assetData?.features?.numerical.length > 0
    ) {
      const numericalFeatures = _.cloneDeep(assetData?.features.numerical);
      numericalFeatures.push('id', 'ID', 'Id');
      numericalFeatures.forEach((feature: string) => {
        if (
          feature in row &&
          row[feature] !== undefined &&
          row[feature] !== null
        ) {
          try {
            row[feature] = Number(row[feature]);
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
    return row;
  };

  const openCSVFile = (urlFile: string): void => {
    const commonConfig = { delimiter: ',' };
    Papa.parse(urlFile, {
      ...commonConfig,
      header: true,
      skipEmptyLines: true,
      download: true,
      complete: (result) => {
        if (
          result?.data !== undefined &&
          result.data.length > 0 &&
          result.meta !== undefined &&
          result.meta.fields !== undefined &&
          result.meta.fields.length > 0
        ) {
          const keys = result.meta.fields.slice(0, result.meta.fields.length);
          const rows = result.data.map((row) =>
            convertStringValuesNumericalColumnToNumber(row as Row)
          );
          const tableData: TableData = { rows, keys };
          setTableData(tableData);
        }
      }
    });
  };

  const headerComponent = (
    <Box className="bulk-prediction-header">
      <Typography style={{ fontSize: 14 }}>Insights</Typography>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography style={{ fontSize: 18, fontWeight: 800 }}>
        Bulk predictions
      </Typography>
      <CloseIcon
        style={{ marginLeft: 'auto', cursor: 'pointer' }}
        onClick={() => {
          closeModal();
        }}
      />
    </Box>
  );

  const bodyHeaderComponent = (
    <>
      <Box className="body-header-container">
        <Box className="bulk-prediction-header-title">
          {assetData?.targetVariable !== undefined &&
            assetData?.targetVariable !== '' && (
              <Typography component="div" className="title-1">
                <Box fontWeight="bold">PREDICT</Box>
                {assetData?.targetVariable}
              </Typography>
            )}
          {assetData?.modelName !== undefined &&
            assetData?.modelName !== '' && (
              <Typography component="div" className="title-1">
                <Box fontWeight="bold">MODEL USED</Box>
                {assetData?.modelName}
              </Typography>
            )}
        </Box>
      </Box>
      <Box className="body-header-container-sub-title ">
        <Typography className="sub-title-1">
          {assetData?.description}
        </Typography>
        <Typography className="sub-title-2">
          Explore the predicted column and analyse possible important factors
          that impacts this predictions.
        </Typography>
      </Box>
    </>
  );

  const newBodyComponent = (
    <Box className="bulk-predictions-body-container">
      {bodyHeaderComponent}
      {generateSummaryLayer()}
    </Box>
  );

  const footerComponent = (
    <Box className="bulk-predictions-footer">
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ status: 'info', open: false, message: '' });
        }}
      >
        <Alert severity={snackBar.status}>{snackBar.message}</Alert>
      </Snackbar>
      <CustomButton
        variant="secondary"
        onClick={() => {
          if (assetData?.documentUrl !== undefined) {
            GAUserEvent(
              `${userLocationVariable.current}_${TRACKED_USER_ACTION_PREDICT_FLOW.DOWNLOAD_BULK_PREDICTIONS}`
            );
            downloadDocumentFromUrl(assetData?.documentUrl, 'file-download');
          }
        }}
        data-cy="insights-modal-previous-button"
        icon={{
          type: ICON_TYPE.DOWNLOAD01,
          position: 'left'
        }}
      >
        Download csv file
      </CustomButton>
      <CustomButton onClick={closeModal} data-cy="insights-modal-save-button">
        Close
      </CustomButton>
    </Box>
  );

  return (
    <>
      <ModalComposed
        className="bulk-predictions-modal"
        size={ModalSize.COMPOSED_FULL}
        open={modalController.type === MODAL_TYPES.BULK_PREDICTIONS}
        header={headerComponent}
        body={newBodyComponent}
        footer={footerComponent}
      />
    </>
  );
};

export default BulkPredictions;
